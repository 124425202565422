
#review-patient .owl-prev {
    position: absolute;
    right: 25px;
    bottom: -50px;
    background: none;
    border: 1px solid #666767;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    width: 20px;
    height: 20px;
    font-family: FontAwesome;
    color: #666767;
    font-size: 9px; /* Approximation due to font substitution */
    font-weight: 400;
}

#review-patient .owl-next {
    position: absolute;
    right: 0px;
    bottom: -50px;
    background: none;
    border: 1px solid #666767;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    width: 20px;
    height: 20px;
    font-family: FontAwesome;
    color: #666767;
    font-size: 9px; /* Approximation due to font substitution */
    font-weight: 400;
}

.rate-reviews-box {
    height: 205px;
}

#review-doctors .owl-prev {
    position: absolute;
    right: 25px;
    bottom: -50px;
    background: none;
    border: 1px solid #666767;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    width: 20px;
    height: 20px;
    font-family: FontAwesome;
    color: #666767;
    font-size: 9px; /* Approximation due to font substitution */
    font-weight: 400;
}

#review-doctors .owl-next {
    position: absolute;
    right: 0px;
    bottom: -50px;
    background: none;
    border: 1px solid #666767;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    width: 20px;
    height: 20px;
    font-family: FontAwesome;
    color: #666767;
    font-size: 9px; /* Approximation due to font substitution */
    font-weight: 400;
}


.speciality-text {
    color: #464646;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
}

.speciality-title {
    padding-bottom: 25px;
    color: #2b8aca;
    font-size: 18px; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
}

#speciality-slider .owl-prev {
    position: absolute;
    right: 25px;
    bottom: -125px;
    background: none;
    border: 1px solid #666767;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    width: 20px;
    height: 20px;
    font-family: FontAwesome;
    color: #666767;
    font-size: 9px; /* Approximation due to font substitution */
    font-weight: 400;
}

#speciality-slider .owl-next {
    position: absolute;
    right: 0px;
    bottom: -125px;
    background: none;
    border: 1px solid #666767;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    width: 20px;
    height: 20px;
    font-family: FontAwesome;
    color: #666767;
    font-size: 9px; /* Approximation due to font substitution */
    font-weight: 400;
}