html, body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif !important;
    background-color: #fff;
    max-width: 100% !important;
    /*overflow-x: hidden !important;*/
    font-size: 16px;
}

/*body > :not(.pace),body:before,body:after {*/
    /*-webkit-transition:opacity .4s ease-in-out;*/
    /*-moz-transition:opacity .4s ease-in-out;*/
    /*-o-transition:opacity .4s ease-in-out;*/
    /*-ms-transition:opacity .4s ease-in-out;*/
    /*transition:opacity .4s ease-in-out*/
/*}*/

/*body:not(.pace-done) > :not(.pace),body:not(.pace-done):before,body:not(.pace-done):after {*/
    /*opacity:0*/
/*}*/

.mt-20 {
    margin-top: 20px;
}

a, img {
    outline: 0 !important;
}

.btn {
    transition: background 0.4s;
    outline: 0 !important;
}

.error {
    color: red;
}

.relative {
    position: relative;
}

.mb-15 {
    margin-bottom: 15px;
}

.home-top-section {
    background: url("../image/img-slider-1.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.search-top-bg {
    background: url("../image/search-top-bg.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.home-top-section-1 {
    background: url("../image/home-top-img-1.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.home-top-section-2 {
    background: url("../image/img-slider-1.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.about-top-img {
    background: url("../image/img-slider-1.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.home-top-title {
    color: #606060;
    font-size: 48px; /* Approximation due to font substitution */
    font-weight: 300;
}

.search-top-title {
    color: #606060;
    font-size: 48px; /* Approximation due to font substitution */
    font-weight: 300;
}

.home-top-text {
    color: #606060;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding-bottom: 150px;
}

.home-search-box {
    padding: 5px 3px 5px 2px;
    background: #FFFFFF;
}

.home-search-position {
    z-index: 10;
    position: absolute;
    bottom: 0;
    left: 0;
}

.width-100 {
    width: 100%;
}

.search-dropdown {
    width: 100%;
    height: 50px;
    color: #333;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.pad-top-15 {
    padding-top: 15px;
}

.search-button {
    width: 100%;
    height: 50px !important;
    background: #5aaa83;
    color: #ffffff;
    border: 1px solid #5aaa83;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 13px 0;
}

.pad-r-l-2 {
    padding: 0 2px 0 3px;
}

.no-margin {
    margin: 0 !important;
}

.drop-menu-style {
    width: 100% !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin-top: 0;
}

.home-top-search-text {
    color: #ffffff;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding: 8px 0;
    margin: 10px 0 15px;
    background-color: rgba(86, 86, 86, 0.57);
}

.profile-top-bg {
    background: url("../image/specialist-img.jpg") repeat-y;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.specialist-bg-img {
    background: url("../image/2nd-bg.jpg") repeat-y;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.how-work-title {
    color: #ffffff;
    font-size: 36px; /* Approximation due to font substitution */
    font-weight: 300;
    text-align: left;
    padding-bottom: 25px;
}

.how-work-text {
    color: #464646;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
}

.about-top-title {
    color: #ffffff;
    font-size: 48px; /* Approximation due to font substitution */
    font-weight: 300;
    text-align: left;
}

.about-top-text {
    color: #606060;
    font-size: 18px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 30px; /* Approximation due to font substitution */
    text-align: left;
}

.top-menu {
    background: none;
    margin: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 15px 0;
    border-bottom: 1px solid #FFFFFF !important;
}

.no-pad-l-r {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.no-pad-l {
    padding-left: 0 !important;
}

.logo-box-pad {
    margin-left: 0 !important;;
    padding: 0;
}

.menu-text {
    color: #474747;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: right;
}

.sign-up-button {
    color: #2e8aca !important;
    border: 1px solid #2e8aca;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 5px 25px !important;
    margin-top: 10px;
}

.sign-up-button:hover {
    color: #FFFFFF !important;
    background: #2e8aca !important;

}

.about-top-pad {
    padding: 128px 0;
}

.about-text-1 {
    color: #4e4e4e;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
}

.about-text-2 {
    color: #4e4e4e;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 30px; /* Approximation due to font substitution */
    text-align: left;
    padding-bottom: 25px;
}

.about-title {
    color: #2b8aca;
    font-size: 36px; /* Approximation due to font substitution */
    font-weight: 300;
    text-align: left;
}

.pad-bot-75 {
    padding-bottom: 75px;
}

.pad-top-75 {
    padding-top: 75px;
}

.pad-bot-50 {
    padding-bottom: 50px;
}

.pad-top-57 {
    padding-top: 57px;
}

.pad-bot-100 {
    padding-bottom: 100px;
}

.health-care-text {
    color: #606060;
    font-size: 18px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
}

.health-care-title {
    color: #606060;
    font-size: 48px; /* Approximation due to font substitution */
    font-weight: 300;
    padding: 0 0 15px;
}

.health-care-bg {
    background: url("../image/health-care-bg-img.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.health-care-find-button {
    width: 100%;
    color: #FFFFFF !important;
    background: #2e8aca;
    border: 2px solid #2e8aca;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: center;
    padding: 15px 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.health-care-find-button:hover {
    color: #2e8aca !important;
    background: #FFFFFF;

}

.health-care-sign-up-button {
    width: 100%;
    color: #2e8aca !important;
    background: none;
    border: 2px solid #2e8aca;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: center;
    padding: 15px 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.health-care-sign-up-button:hover {
    color: #FFFFFF !important;
    background: #2e8aca;
}

.pad-top-25 {
    padding-top: 25px;
}

.health-care-section-pad {
    padding: 157px 15px;
}

.bot-list {
    list-style: none !important;
}

.bot-text, .bot-text:visited, .bot-text:active, .bot-text:hover {
    color: #9a9a9a;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: right !important;
}

.number-of-results {
    color: #606060;
    font-size: 18px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding-top: 30px;
}

.pad-top-50 {
    padding-top: 50px;
}

.sort-by-text {
    color: #333;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
    padding-right: 15px;
    padding-top: 3px;
}

.sort-by-dropdown {
    color: #333;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
    border: 1px solid #333;
    border-radius: 0;
    padding: 2px 30px 2px 15px;
    background: none !important;
}

.sort-by-dropdown span {
    position: absolute;
    right: 10px;
}

.no-border {
    border: none !important;
}

.search-list-view {
    color: #333 !important;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
    border: 1px solid #333 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    padding: 6px 30px 5px 30px !important;
    margin-right: 10px !important;
    background: none !important;
}

.search-map-view {
    color: #333 !important;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
    border: 1px solid #333 !important;
    border-radius: 0 !important;
    padding: 6px 30px 5px 30px !important;
    background: none !important;
}

.active .search-list-view {
    color: #2b8aca !important;
    border: 1px solid #2b8aca !important;
}

.active .search-map-view {
    color: #2b8aca !important;
    border: 1px solid #2b8aca !important;
}

.search-profile-img {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 155px !important;
    height: 155px !important;
}

.search-profile-img-testimonial {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    max-width: 120px;
    /*height: 90px;*/
}

.search-profile-name {
    color: #2b8aca;
    font-size: 24px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding-bottom: 5px;
}

.review-text {
    color: #2b8aca;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
    padding-left: 10px;
    margin-top: -2px;
}

.review-text-white {
    color: #FFFFFF;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
    padding-left: 10px;
    margin-top: -2px;
}

.star-box {
    padding-top: 3px;
}

.search-profile-speciality {
    color: #2b8aca;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding-top: 5px;
    padding-bottom: 25px;
}

.search-profile-text {
    color: #555555;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding-bottom: 25px;
}

.search-profile-distance {
    color: #333;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding: 15px 0;
}

.profile-distance-white {
    color: #FFFFFF;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
}

.bot-border {
    padding-top: 50px;
    border-bottom: 1px solid #333;
    opacity: 0.2;
}

.pad-bot-150 {
    padding-bottom: 150px;
}

.border-1 {
    border-top: 1px solid #333;
    z-index: 5;
}

.home-top-container-pad {
    padding: 72px 15px 75px;
}

.login-title {
    color: #ffffff;
    font-size: 60px !important; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
    padding: 126px 0;
}

.login-button-size {
    width: 50%;
}

.profile-options {
    width: 25%;
}

.login-patient-button {
    width: 100%;
    color: #333;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
    border: 1px solid #333 !important;

    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;

    background: none !important;
    padding: 20px 0 !important;
    transition: background 0.4s;
}

.login-specialist-button {
    width: 100%;
    color: #333 !important;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
    border: 1px solid #333 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    background: none !important;
    padding: 20px 0 !important;
    transition: background 0.4s;
}

.active .login-patient-button {
    background: #5aaa83 !important;
    color: #FFFFFF !important;
    border: 1px solid #5aaa83 !important;
}

.active .login-specialist-button {
    background: #2b8aca !important;
    color: #FFFFFF !important;
    border: 1px solid #2b8aca !important;
}

.form-element-l {
    color: #333;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
}

.form-element-i {
    color: #333;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
    height: 50px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.log-in-button-patient, .log-in-button-patient:active, .log-in-button-patient:visited {
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    color: #ffffff !important;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    background: #5aaa83 !important;
    padding: 13px 0 !important;
    border: 1px solid #5aaa83 !important;
}

.log-in-button-patient:hover {
    background: #ffffff !important;
    color: #5aaa83 !important;
}

.log-in-button-specialist {
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    color: #ffffff !important;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    background: #2b8aca !important;
    padding: 13px 0 !important;
    border: 1px solid #2b8aca !important;
}

.log-in-button-specialist:hover {
    background: #ffffff !important;
    color: #2b8aca !important;
}

.log-in-or-text {
    color: #333;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: center !important;
    padding: 5px 0;
}

.log-in-button-facebook-patient {
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    color: #5aaa83 !important;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    background: #FFFFFF !important;
    padding: 13px 0 !important;
    border: 2px solid #5aaa83 !important;
    margin-bottom: 10px;
}

.log-in-button-facebook-patient:hover {
    background: #5aaa83 !important;
    color: #FFFFFF !important;
}

.log-in-button-facebook-specialist {
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    color: #2b8aca !important;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    background: #FFFFFF !important;
    padding: 13px 0 !important;
    border: 2px solid #2b8aca !important;
    margin-bottom: 10px;
}

.log-in-button-facebook-specialist:hover {
    background: #2b8aca !important;
    color: #FFFFFF !important;
}

.dont-have-account-box-specialist {
    border: 1px solid #2b8aca !important;
    padding: 15px !important;
}

.dont-have-account-text {
    color: #333333;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 500;
    text-align: left;
    margin-top: 15px;
}

.create-account-specialist {
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    color: #ffffff !important;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
    background: #2b8aca !important;
    padding: 13px 0 !important;
    border: 1px solid #2b8aca !important;
}

.create-account-specialist:hover {
    background: #FFFFFF !important;
    color: #2b8aca !important;
}

.create-account-patient {
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    color: #ffffff !important;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
    background: #5aaa83 !important;
    padding: 13px 0 !important;
    border: 1px solid #5aaa83 !important;
}

.create-account-patient:hover {
    background: #FFFFFF !important;
    color: #5aaa83 !important;
}

.dont-have-account-box-patient {
    border: 1px solid #5aaa83 !important;
    padding: 15px !important;
}

.pad-top-150 {
    padding-top: 150px;
}

.margin-top-27 {
    margin-top: 27px;
}

.margin-top-28 {
    margin-top: 28px;
}

.margin-top-83 {
    margin-top: 83px;
}

.register-step-3-title {
    color: #333333;
    font-size: 18px; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
}

.register-step-3-text {
    color: #333333;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding-top: 50px;
}

.howItWorksContainer {
    background-color: #f9f9f9;
}

.profile-review-button {
    color: #333 !important;
    font-size: 14px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    border: 1px solid #F0F0F0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    background: #F0F0F0 !important;
    padding: 20px 0 !important;
    z-index: 10;

    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
}
.profile-review-button span{
    font-size: 24px;
    margin-right: auto;
    padding-left: 15px;
}
.profile-review-button p{
    margin-right: auto;
    margin-bottom: 0;
    padding-right: 15px;
}

.active .profile-review-button {
    background: #2b8aca !important;
    border: 1px solid #2b8aca !important;
    color:#E4E4E4 !important;
}
.profile-documents-box{
    background: #F4F9FC ;
    padding:15px;
}
.profile-documents-box-button{
    background: #D9EAF5 ;
    color:#2E8ACA;
    width: 100%;
    border-radius: 0;
    padding:  15px;
    font-size: 18px;
}
.profile-documents-box-button:hover{
    background: #2E8ACA ;
    color:#D9EAF5;
}
.profile-documents-box-text{
    color:#2E8ACA;
    font-size: 22px;
    padding-top:12px;
}
.profile-documents-box-text span{
    font-size: 24px;
    padding-right: 10px;
}

.profile-info-title {
    color: #2b8aca;
    font-size: 36px; /* Approximation due to font substitution */
    font-weight: 300;
    padding-bottom: 10px;
}

.pad-bot-25 {
    padding-bottom: 25px;
}

.profile-info-text {
    color: #4e4e4e;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
    padding-bottom: 0;
}

.profile-info-text-paragraph {
    color: #4e4e4e;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 30px; /* Approximation due to font substitution */
    text-align: left;
    padding-bottom: 25px;
}

.profile-info-stats-title {
    color: #2b8aca;
    font-size: 18px; /* Approximation due to font substitution */
    font-weight: 400;
    padding-top: 25px;
}

.no-pad-top {
    padding-top: 0 !important;
}

.user-profile-review {
    color: #4e4e4e;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
    padding-bottom: 5px;
}

.profile-top-content-pad {
    padding: 68px 0 0 0;
}

.how-work-bg {
    background: #2b8aca;
    height: 100%;
}

.how-work-list {
    padding-left: 0 !important;
    padding-top: 50px;
    list-style: none;
}

.pad-right-50 {
    padding-right: 50px;
}

.pad-left-75 {
    padding-left: 75px;
}

.fine-specialist-button {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: #FFFFFF;
    color: #3e3e3e;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding: 10px 35px;
    border: 1px solid #FFFFFF;
}

.fine-specialist-button:hover {
    background: none;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
}

.how-work-video-title {
    color: #464646;
    font-size: 36px; /* Approximation due to font substitution */
    font-weight: 300;
    text-align: left;
}

.how-work-video-text {
    color: #464646;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
    padding: 25px 0 75px;
}

.review-tab {
    width: 50%;

}

.home-review-tab-text {
    width: 100%;
    border: 1px solid #b0b0b0 !important;
    color: #b0b0b0 !important;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-decoration: none;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    padding: 20px 0 !important;
}

.active .home-review-tab-text {
    background: #2b8aca !important;
    border-color: #2b8aca !important;
    color: #FFFFFF !important;
}

.home-title {
    color: #2b8aca;
    font-size: 36px; /* Approximation due to font substitution */
    font-weight: 300;
}


.home-text {
    color: #464646;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
}


.benefits-bg {
    background: #f9f9f9;
}

.benefits-type-box {
    width: 100%;
    background: #FFFFFF !important;
    margin-bottom: 10px !important;
}

.benefits-type {
    color: #333 !important;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
    border: none !important;
}

.benefits-type span {
    position: absolute;
    right: 15px;
    top: 10px;
    display: none;
}

.active .benefits-type span {
    display: block;
}


.menu-border-bot {
    border-bottom: 1px solid #FFFFFF;
}

#subMenu {
    display: none;
}

.subMenuContainer {
    position: absolute;
    z-index: 20;
    width: 100%;
}

.no-pad-r {
    padding-right: 0 !important;
}

.search-box {
    position: absolute;
    bottom: 0px;
    z-index: 4;
}

.phone-menu-display-on {
    display: none !important;
}

.phone-menu-display-off {
    display: block !important;
}

.phone-display-on {
    display: none;
}

.phone-display-off {
    display: block;
}


.how-work-phone-display-off {
    display: block;
}

.how-work-phone-display-on {
    display: none;
}

.facebook-button {
    color: #3b5998;
    font-size: 30px;
    font-weight: 400;
    line-height: 24px;
}

.twitter-button {
    color: #00aced;
    font-size: 30px;
    font-weight: 400;
    line-height: 24px;
    padding: 0 15px;
}

.linkedin-button {
    color: #0077b5;
    font-size: 30px;
    font-weight: 400;
    line-height: 24px;
}

.blog-article-title {
    color: #2b8aca;
    font-size: 48px; /* Approximation due to font substitution */
    font-weight: 300;
    padding-top: 25px;
}

.blog-article-subtitle {
    color: #555555;
    font-size: 30px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 30px; /* Approximation due to font substitution */
    padding-bottom: 50px;
    padding-top: 25px;

}

.blog-article-date {
    color: #898989;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding-bottom: 20px;
}

.blog-article-text {
    color: #555555;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 30px; /* Approximation due to font substitution */
    padding-bottom: 25px;
}

.article-author-box {
    border: 1px solid #dcdcdc;
    padding: 50px;
}

.blog-author-img {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 155px !important;
    height: 155px !important;
}

.blog-author-name {
    color: #2b8aca;
    font-size: 24px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding: 15px 0;
}

.blog-author-star {
    color: #2b8aca;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding: 0 4px;
}

.blog-author-star-box {
    margin-left: -52px;
    padding-left: 50%;
}

.blog-author-title {
    color: #2b8aca;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding-bottom: 20px;
}

.blog-author-text {
    color: #555555;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */

}

.blog-about-doctor {
    width: 100%;
    background: #FFFFFF;
    color: #5aaa83;
    border: 1px solid #5aaa83;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    padding: 20px 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin-top: 15px;
}

.blog-about-doctor:hover {
    background: #5aaa83;
    color: #FFFFFF;
}

.blog-get-in-touch {
    width: 100%;
    background: #5aaa83;
    color: #FFFFFF;
    border: 1px solid #5aaa83;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    padding: 20px 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin-top: 15px;
    margin-bottom: 25px;
}

.blog-get-in-touch:hover {
    background: #FFFFFF;
    color: #5aaa83;
}

.other-article-name {
    color: #2b8aca;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    padding-top: 5px;
}

.margin-top-25 {
    margin-top: 25px !important;
}

.newsletter-border {
    border-bottom: 1px solid rgb(152, 190, 216);
    padding-top: 33px;
}

.newsletter-title {
    color: #2e8aca;
    font-size: 24px; /* Approximation due to font substitution */
    font-weight: 400;
    padding: 25px 0 10px;
}

.newsletter-text {
    color: #555555;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
}

.pad-top-100 {
    padding-top: 100px;
}

.blog-top-article-bg {
    background: url("../image/blog-home-bg.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.blog-top-article-bg-2 {
    background: url("../image/blog-article-img.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.blog-top-article-box {
    padding: 303px 15px 50px !important;
}

.blog-top-article-category {
    width: auto !important;
    color: #111111;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    background: #ffffff;
    padding: 1px 15px;
    display: inline-block;
}

.blog-top-article-title {
    color: #ffffff;
    font-size: 48px; /* Approximation due to font substitution */
    font-weight: 300;
    padding: 15px 0 25px;
    height: 168px;
}

.blog-top-article-author-img {
    width: 66px !important;
    height: 66px !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.blog-top-article-author-name {
    color: #ffffff;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
}

.blog-top-article-date {
    color: #ffffff;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
}

.blog-top-article-author-box {
    padding: 10px 0 0 15px;
}

.blog-top-article-button {
    color: #191919;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    background: #FFFFFF;
    padding: 11px 35px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin-top: 10px;
}

#top-articles .owl-next {
    position: absolute;
    bottom: 0;
    left: 51%;
}

#top-articles .owl-prev {
    position: absolute;
    bottom: 0;
    left: 49%;
}

#top-articles .owl-nav {
    margin: 0 !important;
}

.blog-article-category-search-label {
    padding-right: 10px;
    text-transform: uppercase;
}

.articleCategory {
    background-color: #EEEEEE;
    color: #555555;
    text-align: center;
    padding: 10px 20px;
    display: inline-block;
    min-width: 80px;
    margin: 8px 0 0 8px;
}

.blog-article-category-search, .blog-article-category-search:hover {
}

.blog-article-category-search-active, .blog-article-category-search-active:hover {
    background-color: #2e8aca !important;
    color: #fff;
}

.blog-article-category-search-box {
    padding: 18px 0;
    font-size: 14px;
}

.blog-article-img {
    width: 360px;
    height: 230px;
}

.blog-article-category {
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: #ffffff;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    background: #2e8aca;
    padding: 0 10px;
}

.blog-home-article-title {
    color: #2b8aca;
    font-size: 24px; /* Approximation due to font substitution */
    font-weight: 400;
    padding: 15px 0;
    height: 133px;
}

.blog-home-article-text {
    color: #555555;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding-bottom: 25px;
    height: 145px;
}

.blog-home-article-author-name {
    color: #555555;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
}

.blog-home-article-date {
    color: #555555;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
}

.blog-home-article-author-img {
    width: 50px !important;
    height: 50px !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.blog-home-article-author-box-text {
    padding-left: 15px;
}

.blog-home-article-author-box {
    padding-bottom: 25px;
}

.blog-home-article-button {
    width: 100%;
    color: #2e8aca;
    background: #FFFFFF;
    border: 1px solid #2e8aca;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 12px 0;

}

.blog-home-article-button:hover {
    color: #FFFFFF !important;
    background: #2e8aca;
}

.profile-star {
    padding: 0 4px;
    color: #ffffff;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
}

.profile-name-white {
    color: #FFFFFF;
    font-size: 30px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding: 15px 0;
}

.profile-star-box {
    padding-left: 50%;
    margin-left: -52px;
}

.profile-user-title {
    color: #ffffff;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding-bottom: 15px;
}

.user-page-profile-img {
    width: 155px !important;
    height: 155px !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.profile-social-button {
    color: #ffffff;
    font-size: 18px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding: 0 4px;
}

.profile-top-bar {
    padding: 10px 10px 0 10px;
    background: #FFFFFF;
}

.search-profile-more-button {
    background: #FFFFFF;
    color: #5aaa83;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 16px 0;
    border: 1px solid #5aaa83;
}

.search-load-more-button {
    color: #555555;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    background: #FFFFFF;
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 15px 0;
}

.search-profile-more-button:hover {
    color: #ffffff;
    background: #5aaa83;
}

.profile-get-in-button {
    color: #ffffff;
    background: #5aaa83;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 16px 0;
    border: 1px solid #5aaa83;
}

.profile-get-in-button:hover {
    background: #FFFFFF;
    color: #5aaa83;
}

.pad-bot-15 {
    padding-bottom: 15px;
}

.pad-l-r-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.profile-info-text-special {
    color: #464646;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px;
}

.profile-article-img {
    width: 750px;
    height: 470px;
}

.profile-article-title {
    color: #2b8aca;
    font-size: 30px; /* Approximation due to font substitution */
    font-weight: 400;
    padding: 25px 0 10px;
}

.profile-article-text {
    color: #555555;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 30px; /* Approximation due to font substitution */
    padding: 0 0 25px;
}

.profile-article-date {
    color: #898989;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    padding: 0 0 25px;
}

.profile-article-button {
    width: 100%;
    color: #2e8aca;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    border: 1px solid #2e8aca;
    background: #ffffff;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 12px 0;
}

.profile-article-button:hover {
    color: #ffffff;
    background: #2e8aca;
}

.search-result-bg {
    background: #f5f5f5;
}

.results-box {
    background: #FFFFFF;
    padding: 25px 15px 15px;
    margin-bottom: 25px;
}

.home-video-box {
    width: 845px;
    height: 456px;
    padding-bottom: 25px;
}

.how-work-text-box {
    background: #ffffff;
    padding: 15px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.blog-article-top-img {
    /*background: url("../image/blog-article-img.jpg") no-repeat center center;*/
    /*-webkit-background-size: cover;*/
    /*-moz-background-size: cover;*/
    /*-o-background-size: cover;*/
    /*background-size: cover;*/
    /*padding-top: 623px;*/

    width: 100%;
    display:block;
    height: 623px;
    object-fit: cover;
}

.forgottenPasswordContainer a {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.forgottenPasswordContainer {
    margin-bottom: 15px;
}

.padding-top {
    padding-top: 30px;
}

.benefits-text-1 {
    color: #2b8aca;
    font-size: 48px;
    font-weight: 300;
    text-align: center;
}

.color-blue {
    color: #0F7AB0;
}

.panel-heading .accordion-toggle:after {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    color: white;
    padding-top: 4px;
    font-size: 10px;
}

.panel-heading .accordion-toggle.collapsed:after {
    content: "\e080";
}

.accordion-panel-heading {
    background-color: #2B8AC9;
    color: white !important;
}

.accordion-font {
    font-weight: 400;
    font-size: 15px;
    color: white;
}

.panel-border {
    border-color: #ddd;
}

.health-care-icons {
    padding-top: 20px;
    font-size: large;
}

.pad-top-40 {
    padding-top: 40px;
    font-size: large;
}

.patient-benefit-text {
    font-size: 18px;
    font-weight: 500;
}


.pad-l-35 {
    padding-left: 35px;
}

.responsiveSubmennuMobile {
    padding: 0;
    margin: 0;
    list-style: none;
}

.responsiveSubmennuMobile li {
    width: 100%;
}

.responsiveSubmennuMobile li a {
    width: 100%;
    text-align: left;
    display: inline-block;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.teamMemberSmall {
    display: none;
}

.owl-carousel .item .slideContainer, .sliderLoading {
    min-height: 510px;
}

.howItWorksContainer, .how-work-phone-display-on .how-work-bg {
    margin-top: 55px;
}

.inputHeight {
    height: 80px;
}

.inputHeight .dropdownText {
    padding-top: 10px;
}

.btnSearch {
    padding-top: 24px;
    padding-bottom: 24px;
    height: 80px !important;
}

#dropExpertise {
    border: 1px solid #cccccc;
}

#dropExpertise input {
    margin-top: 26px;
    width: 370px;
    border: 0px;
    outline: none;
    padding-left: 12px;
}

#dropExpertise input::-ms-clear {
    display: none;
}

.btnScrollDown {
    position: fixed;
    z-index: 100;
    bottom: 35px;
    right: 10px;
    color: #FFFFFF;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #2b8aca;
    width: 40px;
    height: 40px;
    padding: 0 !important;
    text-align: center !important;
    border: none;
}

.btnSpecialistActive {
    color: #FFFFFF !important;
    background: #2b8aca !important;
    border-color: #2b8aca !important;
}

.btnSearchColor {
    color: #7d7d7d;
}

.btnSearchColor::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #7d7d7d;
    opacity: 1;
}

.btnSearchColor::-moz-placeholder { /* Firefox 19+ */
    color: #7d7d7d;
    opacity: 1;
}

.btnSearchColor:-ms-input-placeholder { /* IE 10+ */
    color: #7d7d7d;
    opacity: 1;
}

.btnSearchColor:-moz-placeholder { /* Firefox 18- */
    color: #7d7d7d;
    opacity: 1;
}

.btnSignUpSocial {
    margin-top: 15px;
}

.pad-bot-50 {
    padding-bottom: 50px;
}

.backBtn, .backBtn:link, .backBtn:active, .backBtn:visited {
    width: 37px;
    height: 37px;
    position: absolute;
    z-index: 20;
    top: 25px;
    right: 15px;
    background: url('../image/close.png') no-repeat center center;
    cursor: pointer;
}

.pulseAnimation {
    -webkit-animation: pulse;
    -o-animation: pulse;
    animation: pulse;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}


.profileName, .doctosSpeciality {
    margin: 0;
}

.blinking-cursor {
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
}


.videoContainer {
    padding-bottom: 25px;
}

.login-patient-button {
    width: 100%;
    color: #333;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
    border: 1px solid #333 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    background: none !important;
    padding: 37px 0 !important;
    height: 91px;
    transition: background 0.4s;
}

.login-patient-button-login {
    width: 100%;
    color: #333;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
    border: 1px solid #333 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    background: none !important;
    padding: 37px 0 !important;
    height: 91px;
    transition: background 0.4s;
}

.signup-specialist-button {
    width: 100%;
    color: #333 !important;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
    border: 1px solid #333 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    background: none !important;
    padding: 37px 0 !important;
    height: 91px;
    transition: background 0.4s;
}

.active .login-patient-button-login {
    background: #5aaa83 !important;
    color: #FFFFFF !important;
    border: 1px solid #5aaa83 !important;
}

.active .login-patient-button {
    background: #5aaa83 !important;
    color: #FFFFFF !important;
    border: 1px solid #5aaa83 !important;
}

.active .login-specialist-button {
    background: #2b8aca !important;
    color: #FFFFFF !important;
    border: 1px solid #2b8aca !important;
}

@media screen and (max-width: 511px) {
    .signup-specialist-button {
        width: 100%;
        color: #333 !important;
        font-size: 12px; /* Approximation due to font substitution */
        font-weight: 900;
        line-height: 24px; /* Approximation due to font substitution */
        border: 1px solid #333 !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
        background: none !important;
        padding: 30px 0 !important;
        height: 91px;
        transition: background 0.4s;
    }
}

@media screen and (min-width: 987px) {
    .signup-specialist-button {
        width: 100%;
        color: #333 !important;
        font-size: 12px; /* Approximation due to font substitution */
        font-weight: 900;
        line-height: 24px; /* Approximation due to font substitution */
        border: 1px solid #333 !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
        background: none !important;
        padding: 30px 0 !important;
        height: 91px;
        transition: background 0.4s;
    }
}

@media screen and (min-width: 1199px) {
    .signup-specialist-button {
        width: 100%;
        color: #333 !important;
        font-size: 12px; /* Approximation due to font substitution */
        font-weight: 900;
        line-height: 24px; /* Approximation due to font substitution */
        border: 1px solid #333 !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
        background: none !important;
        padding: 37px 0 !important;
        height: 91px;
        transition: background 0.4s;
    }
}

.login-specialist-button {
    width: 100%;
    color: #333 !important;
    font-size: 12px; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
    border: 1px solid #333 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    background: none !important;
    padding: 37px 0 !important;
    height: 91px;
    transition: background 0.4s;
}

@media screen and (min-width: 987px) {
    .login-specialist-button {
        width: 100%;
        color: #333 !important;
        font-size: 12px; /* Approximation due to font substitution */
        font-weight: 900;
        line-height: 24px; /* Approximation due to font substitution */
        border: 1px solid #333 !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
        background: none !important;
        padding: 30px 0 !important;
        height: 91px;
        transition: background 0.4s;
    }
}

@media screen and (min-width: 1199px) {
    .login-specialist-button {
        width: 100%;
        color: #333 !important;
        font-size: 12px; /* Approximation due to font substitution */
        font-weight: 900;
        line-height: 24px; /* Approximation due to font substitution */
        border: 1px solid #333 !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
        background: none !important;
        padding: 30px 0 !important;
        height: 91px;
        transition: background 0.4s;
    }
}

.btnSpecialistActive {
    color: #FFFFFF !important;
    background: #2b8aca !important;
    border-color: #2b8aca !important;
}

.ajaxLoader {
    position: absolute;
    z-index: 30;
    width: 100%;
    height: 100%;
    background: url('../image/loader10.gif') no-repeat center center;
    display: none;
    top: 0;
}

.loadMoreButtonReviewsContainer {
    margin-top: 45px;
}

.searchDescription {
    height: 150px;
    overflow: hidden;
}

.searchName {
    margin-top: 15px;
    height: 50px;
    overflow: hidden;
}

.notSponsored {
    margin-top: 20px;
}

.sponsoredAccount {
    height: 20px;
}

.sliderLoading {
    height: 510px;
}

.profileName, .profileExpertise {
    margin: 0;
}

.articleOverlay {
    background: url('../image/_overlay_homepage.png') repeat;
}

.viewDoctorDescription {
    padding-top: 35px;
    padding-bottom: 25px;
}

.viewDoctorName {
    text-align: center !important;
    padding-bottom: 25px;
}

.articleBar {
    padding-top: 10px;
    padding-bottom: 10px;
    background: #FFFFFF;
    border-bottom: 1px solid #333;
    position: fixed;
    z-index: 30000;
    width: 100%;
    top: 0;
}

.btnBarBack {
    background: #FFFFFF;
    color: #5aaa83;
    border: 1px solid #5aaa83;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.btnBarPublish {
    background: #5aaa83;
    color: #FFFFFF;
    border: 1px solid #5aaa83;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.previewMenuContainer {
    padding-top: 54px;
}

.btnMobile {
    background: #FFFFFF;
    color: #2e8aca;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 5px 25px !important;
    margin-top: 10px;
    border: 1px solid #2e8aca;
}

.btnMobile:hover {
    background: #2e8aca !important;
    color: #FFFFFF !important;
}

.doctorsMap {
    margin-top: 35px;
}

.doctorLocationBorder {
    margin-top: 6px;
}

.subMenuContainer {
    background: url('../image/_overlay_homepage.png') repeat;
    border-bottom: none;
}

.closeTestimonial {
    color: #2e8aca;
    opacity: 1 !important;
    font-size: 35px;
    position: absolute;
    z-index: 30;
    right: 20px;
    top: 20px;

}

.doctorImageTopMenu {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.doctorMenuName {
    position: absolute;
    z-index: 100;
    right: -200px;
    width: 200px;
    top: 7px;
}

.doctorNameTopMenu {
    width: 150px;
}

.doctorImageTopMenuContainer {
    width: 50px;
}

.btnTopMenuDropdown {
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.img-round {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.doctorstDisplayType {
    padding-top: 10px;
}

.perPageTextSmall {
    display: none;
}

.topMenuDropdown {
    padding: 7px;
}

.textNameReview {
    text-align: center !important;
}

.btnBackToHome {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    background: #5aaa83;
    padding: 13px 35px;
    border: 1px solid #5aaa83;
}

.noPadBot {
    padding-bottom: 0 !important;
}

.listDoctorBox {
    padding-top: 20px;

}

.borderDoctor {
    padding-top: 20px;
    border-bottom: 1px solid #333;
    opacity: 0.2;
}

.activeMenu:link, .activeMenu:visited, .activeMenu:active, .activeMenu:hover {
    color: #2b8aca !important;
}

.hiddenReadMoreContent {
    margin-left: -3px;
}

.btnProfileRequestAppointment {
    position: fixed;
    z-index: 10;
    bottom: 0;
}

.search-expertise-dropdown .select2-search.select2-search--dropdown, .search-expertise-dropdown .select2-search.select2-search--dropdown .select2-search__field {
    width: 100% !important;
}

.article-publish-container {
    width: 100%;
    position: fixed;
    z-index: 30;
    bottom: 0;
    background: #FFFFFF;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #333;
}

.or-badge {
    position: absolute;
    z-index: 2;
    border: 1px solid #333;
    padding: 5px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color: #333;
    right: -14px;
    top: 33px;
    background: #ffffff;
    font-size: 12px;
    font-weight: 900;
}

.login-doctor-tab-btn a {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.sponsored {
    cursor: pointer;
}

.sponsored-modal-content {
    padding-bottom: 35px;
}

.w-33 {
    width: 33.33% !important;
}

input[type="date"]:before {
    content: attr(placeholder) !important;
    color: #aaa;
    margin-right: 0.5em;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
    content: "";
}

.js-load-week {
    z-index: 10000;
}

input[type="date"]:before {
    content: attr(placeholder) !important;
    color: #aaa;
    margin-right: 0.5em;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
    content: "";
}

input[type="date"]
{
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding-bottom: 10px !important;
    padding-top: 0 !important;
    overflow: hidden;
}

.navbar-default .navbar-nav > li > a {
    color: #333;
}

.telemedicine-text {
    background: #fff568;
    color: #333;
    /*position: absolute;*/
    /*z-index: 2;*/
    width: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
}

.telemedicine-text a {
    color: #333;
    text-decoration: underline !important;
}

.top-menu {
    padding-top: 0;
    padding-bottom: 0;
}

.desktop-menu li a {
    padding-top: 30px;
    padding-bottom: 30px;
}

.desktop-menu li a.sign-up-button {
    margin-top: 24px !important;
}

.desktop-logo {
    padding-top: 18px;
}

.desktop-menu li a.telemedicine, .desktop-menu li a.telemedicine:hover, .desktop-menu li a.telemedicine:active, .desktop-menu li a.telemedicine:visited {
    background: #2b8aca;
    color: #FFFFFF !important;
    margin-left: 25px;
    padding-left: 25px;
    padding-right: 25px;
}

.telemedicine span {
    position: absolute;
    right: 15px;
    top: 17px;
    font-size: 10px;
}

.telemedicine-mobile-menu {
    position: relative !important;
}

.cd-primary-nav-trigger .cd-menu-icon::before, .cd-primary-nav-trigger .cd-menu-icon::after {
    background-color: #333333 !important;
}

.desktop-menu .dropdown-menu {
    width: 100%;
    min-width: 50px;
}


/* Events */

.event-title {
    font-weight: 400;
    font-size: 34px;
    padding-bottom: 10px;
}

.events-border-bottom {
    border-bottom: 1px solid #2e8aca;
    margin-bottom: 30px;
}

.other-events-border-bottom {
    border-bottom: 1px solid #959595;
    margin-bottom: 30px;
}

.event-subtitle {
    font-size: 22px;
    color: #2e8aca;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 20px;
}

.event-info {
    font-size: 16px;
    color: #727272;
    padding-right: 30px;
}

.event-icon {
    margin-right: 15px;
}

/* padding-bottom */

.pb-2 {
    padding-bottom: 10px;
}

.pb-3 {
    padding-bottom: 15px;
}

.pb-4 {
    padding-bottom: 20px;
}

.pb-5 {
    padding-bottom: 30px;
}

/* padding-top */

.pt-4 {
    padding-top: 20px;
}

/* padding-end */

.pe-1 {
    padding-right: 5px;
}

.pe-3 {
    padding-right: 15px;
}

/* margin-bottom */

.mb-1 {
    margin-bottom: 5px;
}

.mb-2 {
    margin-bottom: 10px;
}

.mb-4 {
    margin-bottom: 20px;
}

/* margin-start */
.ms-2 {
    margin-left: 20px;
}

/* margin-end */
.me-1 {
    margin-right: 5px;
}

.event-info-display {
    display: inline-flex;
}

.color-dark {
    color: #383838 !important;
}

.btn-dark {
    background-color: #383838;
    border: 2px solid #383838;
}
.btn-dark:hover {
    color: #383838 !important;
}

/* Event Page */

.event-info-subtitle {
    font-size: 24px;
    color: #2e8aca;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 20px;
}

.color-primary {
    color: #2e8aca;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-34 {
    font-size: 34px;
}

.fw-300 {
    font-weight: 300;
}

.fw-500 {
    font-weight: 500;
}

.event-btn-w {
    width: 25%;
}

.fb-color {
    color: #425894;
}
.twitter-color {
    color: #4d9fec;
}
.linkedin-color {
    color: #3375b0;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.other-events-box {
    background-color: #f7f7f7;
    padding: 30px;
}

/* Modal */

.modal {
    text-align: center;
    padding: 0 !important;
}

/* vertically centered */
.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.modal-content {
    padding: 30px 40px;
}

/* form */

.reg-form-label {
    color: #4d9fec;
    font-size: 18px;
    font-weight: 400;
}

.reg-form-input {
    background-color: #f7f7f7;
    border: none;
    border-radius: 0px;
    box-shadow: none;
    height: 60px;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #383838 !important;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #383838 !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #383838 !important;
}

.reg-form-checkbox {
    background-color: #f7f7f7;
}

/* Checkbox */

.label-container {
    display: block;
    position: relative;
    padding-left: 40px !important;
    margin-bottom: 12px;
    cursor: pointer;
    /* font-size: 22px; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.label-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: #f7f7f7;
}
/* On mouse-over, add a grey background color */
.label-container:hover input ~ .checkmark {
    background-color: #f7f7f7;
}

/* When the checkbox is checked, add a blue background */
.label-container input:checked ~ .checkmark {
    background-color: #2e8aca;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.label-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.label-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f00c';
    color: rgb(255, 255, 255);
}

.br-0 {
    border-radius: 0px;
}

.event-top-section {
    background: url('../image/writing.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.events-top-section {
    background: url('../image/chairs.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@media (max-width: 1200px) {
    .event-info {
        padding-bottom: 20px;
    }

    .event-info-display {
        display: inline-block;
    }
    .sponsor-logo {
        width: 25%;
    }
    .event-btn-w {
        width: 50%;
    }

    .menu-text {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }
}


.event-cover-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
}
.img-border-rad-50{
    border-radius: 50%;
    margin-bottom:15px;
}
.pt-15{
    padding-top:15px;
}
.sponsor-image{
    height: 150px;
    object-fit: cover ;
    width: 100%;
    padding-bottom: 30px;
}
.event-sticky-row-box{
    display: flex;
}
.event-sticky-col-box{
    min-height: 100%;
}
.event-sticky-box{
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
    background-color: #ffffff;
    padding: 15px;
}

.search-keyword {
    color: #2e8aca;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0 5px;
    text-transform: none;
}

.pad-bottom-0{
    padding-bottom: 0!important;
}
.profile-review-text{
    color: #4e4e4e;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.profile-review-text-mini{
    color: #4e4e4e;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
}
.profile-review-text-mini span{
    font-weight: 800;
}
.profile-review-text-bottom{
    text-align: center;
    font-size: 12px;
    letter-spacing: 0px;
    color: #767676;
    padding: 5px 0;
}
.review-page-star{
    color: #4e4e4e;
    font-size: 32px;
    font-weight: 400;
    padding-left: 5px;
}
.profile-review-star-box{
    display: flex;
    justify-content: center;
}
.review-submit-button{
    background: #6EA886;
    border: 1px solid #6EA886;
    font-size: 16px;
    color: #FFFFFF;
    text-transform: capitalize;
    border-radius: 0;
    padding:12px ;
    width: 100%;
}
.review-submit-button:hover{
    color: #6EA886;
    background: #FFFFFF;
}
.profile-review-info-title{
    color: #2b8aca;
    font-size: 24px;
    font-weight: 400;
    padding-bottom: 10px;
}
.profile-review-icon{
    color: #6EA886;
    font-size: 26px;
    padding-bottom: 5px;
}
.profile-top-button-box{
    position:relative;
}
.profile-top-button,.profile-top-button:focus{
    background: #6EA886;
    border: 1px solid #6EA886;
    font-size: 16px;
    color: #FFFFFF;
    border-radius: 10px;
    padding:12px 30px ;
    margin-right: 10px;

    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 5px;
}
.profile-top-button:hover{
    background: #ffffff;
    color: #6EA886;
}

/* The actual popup */
.profile-top-button .profile-top-button-pop-up {
    visibility: hidden;

    background-color: rgba(110, 168,133, 1);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
}

/* Popup arrow */
.profile-top-button .profile-top-button-pop-up::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(110, 168,133, 1) transparent  transparent  transparent;
}


/* The actual popup */
.profile-top-button .profile-left-button-pop-up {
    visibility: hidden;

    background-color: rgba(110, 168,133, 1);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 10px;
    position: absolute;
    z-index: 1;
    top: 0%;
    right: 107%;
    margin-left: 0;
}

/* Popup arrow */
.profile-top-button .profile-left-button-pop-up::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-left: 0;
    border-width: 5px;
    border-style: solid;
    border-color: transparent   transparent  transparent rgba(110, 168,133, 1) ;
}

/* Toggle this class - hide and show the popup */
.profile-top-button .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
}

.profile-top-buttons-box{
    display: flex;
    justify-content: center;
}
.profile-expertise-box{
    background: #F4F9FC ;
    padding: 15px;
}
.padding-top-0{
    padding-top: 0!important;
}
.pad-r-10{
    padding-right:10px;
}
.search-hidden-element{
    display: none;
}
.read-more-search-button,.read-more-search-button:hover,.read-more-search-button:focus{
    background: none;
    border: none;
    color: #4888C5;
    font-size:14px;
}
.appointment-modal-top-section{
    background: #4888C5;
    padding: 20px;
}
.appointment-modal-top-banner{
    background: #386EA0 ;
    padding: 18px 30px;
}
.appointment-modal-top-banner-close-button,.appointment-modal-top-banner-close-button:hover{
    font-size: 28px;
    color: #ffffff!important;
    opacity: 1;
}
.appointment-modal-top-banner-text{
    font-size: 18px;
    color: #FFFFFF;
}
.pad-0{
    padding: 0!important;
}
.appointment-modal-name-white{
    font-size: 24px;
    color: #FFFFFF;
}
.appointment-modal-user-title{
    font-size: 14px;
    color: #FFFFFF;
}
.appointment-form-box{
    padding: 30px;
}
.appointment-form-patient-box{
    padding: 30px;
    background: #F2F5FF ;
}
.appointment-radio label{
    font-weight: normal;
    padding-right: 15px;
    padding-left: 5px;
}
.appointment-modal-icon{
    font-size: 72px;
    color: #FFFFFF;
}
.appointment-modal-done-pad{
    padding: 0 50px;
}
.appointment-modal-done-title{
    font-size: 18px;
    color: #4888C5;
    font-weight: 800;
}
.appointment-modal-done-text {
    color: #4e4e4e;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-bottom: 0;
}

.benefits-icon-box{
    width: 150px;
    padding: 30px;
    background: #4888C5;
    border-radius: 50%;
}
.benefits-icon-box-position{
    margin-left: -75px;
    padding-left: 50%;
    padding-top: 15px;
}
.how-work-image{
    width: 78px;
    height: 78px;
    padding: 10px;
    background: #4888C5;
    border-radius: 50%;
}

.modal::before{
    height: auto!important;
}
/*check in appadmin/css/app.css*/
.cke_notification_warning{
    display: none!important;
}